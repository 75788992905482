import { useEffect } from 'react';
import { Tooltip } from 'bootstrap';

function useTooltip() {
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      new Tooltip(tooltipTriggerEl);
    });
  }, []);
}

export default useTooltip;

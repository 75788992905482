import React from 'react';
import Sidebar from './components/Sidebar';
import useTooltip from './Tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  useTooltip(); 

  return (
    <div className="App" style={{ display: 'flex', justifyContent: 'left' }}>
      <Sidebar />
      <div className="content p-3" style={{ flexGrow: 1 }}>
        <h1 data-bs-toggle="tooltip" title="Welcome to my app!">
          ยินดีต้อนรับสู่แอปพลิเคชันของฉัน
        </h1>
      </div>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import './Sidebar.css';

const Sidebar = () => {
  // ประกาศ state 'active' และฟังก์ชัน 'handleSetActive'
  const [active, setActive] = useState('');

  const handleSetActive = (item) => {
    setActive(item);
  };

  return (
    <div className="d-flex flex-column flex-shrink-0 bg-light" style={{ width: '4.5rem' }}>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="bootstrap" viewBox="0 0 118 94">
          <title>Bootstrap</title>
          <path fillRule="evenodd" clipRule="evenodd" d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z"></path>
        </symbol>

  <symbol id="bootstrap" viewBox="0 0 16 16">
    <path d="M5.062 12.4c.65.607 1.57.916 2.736.916 1.25 0 2.2-.32 2.848-.96.448-.446.67-1.042.67-1.789 0-.586-.18-1.087-.54-1.503-.358-.417-.878-.75-1.558-1 .93-.332 1.395-.946 1.395-1.832 0-.706-.226-1.303-.676-1.79-.596-.663-1.456-.995-2.58-.995-.985 0-1.806.276-2.462.827-.497.448-.798 1.03-.9 1.742h1.453c.063-.36.23-.66.5-.9.344-.303.776-.454 1.295-.454.577 0 1.02.15 1.326.45.308.3.46.7.46 1.2 0 .52-.19.917-.566 1.191-.373.27-.92.406-1.64.406H6.8v1.19h.874c.78 0 1.384.14 1.81.421.423.283.635.7.635 1.25 0 .557-.2.987-.6 1.29-.39.287-.92.431-1.588.431-.664 0-1.17-.165-1.52-.495-.237-.22-.396-.51-.48-.86H4.31c.116.8.468 1.43 1.056 1.9zm-3.696 1.6h3.052V2H1.366v12zm1.512-1.36V3.76h.506v8.88h-.506z"/>
  </symbol>

  <symbol id="house-door" viewBox="0 0 16 16">
    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
  </symbol>

  <symbol id="person-circle" viewBox="0 0 16 16">
    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
  </symbol>

  <symbol id="people" viewBox="0 0 16 16">
    <path d="M13 7a3 3 0 1 0-2.83-4H5.83a3 3 0 1 0-2.82 4 4 4 0 0 0-.83 2.68v.63A2 2 0 0 0 4 12h8a2 2 0 0 0 2-2v-.68a4 4 0 0 0-.83-2.68zM4 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm8-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm-3.106 7H7.106c-.893 0-1.68-.707-1.68-1.58 0-.872.788-1.58 1.68-1.58h1.788c.892 0 1.68.707 1.68 1.58 0 .872-.788 1.58-1.68 1.58z"/>
  </symbol>

  <symbol id="credit-card" viewBox="0 0 16 16">
    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm1 0v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm3 1a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H4z"/>
  </symbol>
        {/* เพิ่ม symbol ไอคอนอื่นๆ ได้ตามต้องการ */}
      </svg>

      <a href="/" className="d-block p-3 link-dark text-decoration-none" title="Icon-only" data-bs-toggle="tooltip" data-bs-placement="right">
  <svg className="bi" width="40" height="32"><use xlinkHref="#bootstrap" /></svg>
  <span className="visually-hidden">Icon-only</span>
</a>
<ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
  <li className="nav-item">
    <a
      href="#"
      className={`nav-link py-3 border-bottom ${active === 'home' ? 'active text-white bg-primary' : ''}`}
      title="หน้าหลัก"
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      onClick={() => handleSetActive('home')}
    >
      <svg className="bi" width="24" height="24"><use xlinkHref="#house-door" /></svg>
    </a>
  </li>
  <li>
    <a
      href="#"
      className={`nav-link py-3 border-bottom ${active === 'dashboard' ? 'active text-white bg-primary' : ''}`}
      title="จัดการโปรไฟล์"
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      onClick={() => handleSetActive('dashboard')}
    >
      <svg className="bi" width="24" height="24"><use xlinkHref="#person-circle" /></svg>
    </a>
  </li>
  <li>
    <a
      href="#"
      className={`nav-link py-3 border-bottom ${active === 'orders' ? 'active text-white bg-primary' : ''}`}
      title="จัดการสมาชิก"
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      onClick={() => handleSetActive('orders')}
    >
      <svg className="bi" width="24" height="24"><use xlinkHref="#people" /></svg>
    </a>
  </li>
  <li>
    <a
      href="#"
      className={`nav-link py-3 border-bottom ${active === 'products' ? 'active text-white bg-primary' : ''}`}
      title="จัดการการชำระเงิน"
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      onClick={() => handleSetActive('products')}
    >
      <svg className="bi" width="24" height="24"><use xlinkHref="#credit-card" /></svg>
    </a>
  </li>

      </ul>
      <div className="dropdown border-top">
        <a href="#" className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="https://github.com/mdo.png" alt="mdo" width="24" height="24" className="rounded-circle" />
        </a>
        <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
          <li><a className="dropdown-item" href="#">New project...</a></li>
          <li><a className="dropdown-item" href="#">Settings</a></li>
          <li><a className="dropdown-item" href="#">Profile</a></li>
          <li><hr className="dropdown-divider" /></li>
          <li><a className="dropdown-item" href="#">Sign out</a></li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
